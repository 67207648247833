import { useState } from "react";
import { Auth } from "aws-amplify";
import { useNavigate } from "react-router-dom";
import countries from "../../countries.json";
import { useTranslation } from "react-i18next";
import { usePostHog } from "posthog-js/react";
import { Button } from "../ui/button";
import { Input } from "@/components/ui/input";
import { Checkbox } from "@/components/ui/checkbox";

import { Loader2 } from "lucide-react";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import * as z from "zod";
import { useToast } from "@/hooks/use-toast";
import { ToastAction } from "@/components/ui/toast";

function SignUpPage() {
  const [selectedCountryCode, setSelectedCountryCode] = useState("+91"); // Default country code
  const [signingUp, setSigningUp] = useState(false);
  const [error, setError] = useState(null);
  const [newsletter, setNewsletter] = useState(true);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const posthog = usePostHog();
  const { toast } = useToast();

  const signUpSchema = z.object({
    email: z.string().email({
      message: "Please enter a valid email address.",
    }),
    password: z
      .string()
      .min(8, {
        message: "Password must be at least 8 characters long.",
      })
      .regex(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)/, {
        message:
          "Password must include at least one uppercase letter, one lowercase letter and one number.",
      }),
    phone: z.string().regex(/^\d+$/, {
      message: "Phone number must contain only digits.",
    }),
  });

  const form = useForm({
    resolver: zodResolver(signUpSchema),
    defaultValues: {
      email: "",
      password: "",
      phone: "",
    },
  });

  const onSubmit = async (values) => {
    setSigningUp(true);
    const fullPhoneNumber = `${selectedCountryCode}${values.phone}`;
    try {
      const signUpResponse = await Auth.signUp({
        username: values.email,
        password: values.password,
        attributes: {
          email: values.email,
          phone_number: fullPhoneNumber,
          "custom:newsletterSubscribed": newsletter.toString(),
        },
      });
      console.log("Sign up success:", signUpResponse);
      posthog?.capture("sign_up_success", {
        email: values.email,
      });
      navigate("/confirm-account", {
        state: {
          username: values.email,
          password: values.password,
          phone: values.phone,
        },
      });
    } catch (error) {
      console.error("Error during sign up:", error);
      if (error.message === "User already exists") {
        toast({
          title: "An account already exists",
          description: "Would you like to log in instead?",
          className:
            "w-[388px] h-[84px] px-4 py-4 bg-[#161b26] rounded-xl shadow border border-[var(--border-tertiary)] justify-start items-center gap-4 inline-flex",
          action: (
            <ToastAction
              altText="Log In"
              onClick={() => navigate("/signin")}
              className="h-10 px-4 py-2 bg-[var(--ring-offset)] rounded-full border border-[var(--border-tertiary)] justify-center items-center gap-2 inline-flex"
            >
              Log In
            </ToastAction>
          ),
        });
      } else {
        toast({
          variant: "destructive",
          description: error.message,
        });
      }
      posthog?.capture("sign_up_failed", {
        email: values.email,
        error: error.message,
      });
      setSigningUp(false);
    }
  };

  return (
    <div className="pt-0 w-full px-0 my-0 mx-auto min-h-screen">
      <div className="flex flex-row">
        <div className="lg:w-1/2 w-full h-screen bg-[var(--ring-offset)] flex-col justify-center items-center gap-2.5 inline-flex">
          <div className="max-w-md">
            <div className="self-stretch h-[470px] rounded-lg flex-col justify-start items-start gap-6 flex w-fit mx-auto">
              <div className="self-stretch h-[62px] flex-col justify-start items-start gap-2 flex">
                <div className="self-stretch text-center text-neutral-50 text-3xl font-bold leading-[30px]">
                  Sign Up
                </div>
                <div className="self-stretch text-center text-[var(--muted-foreground)] text-base font-normal leading-normal">
                  {t("sign_up_below")}
                </div>
              </div>
              <Form {...form}>
                <form
                  onSubmit={form.handleSubmit(onSubmit)}
                  className="w-full space-y-4"
                >
                  <FormField
                    control={form.control}
                    name="email"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel className="text-neutral-50">Email</FormLabel>
                        <FormControl>
                          <Input
                            {...field}
                            placeholder={t("email")}
                            className="bg-gray-900 border-[var(--border-tertiary)] text-neutral-50 rounded-xl py-0"
                            onChange={(e) => {
                              const lowerCaseValue =
                                e.target.value.toLowerCase();
                              field.onChange(lowerCaseValue); // Update the field value
                            }}
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name="password"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel className="text-neutral-50">
                          Password
                        </FormLabel>
                        <FormControl>
                          <Input
                            {...field}
                            type="password"
                            placeholder={t("password")}
                            className="bg-gray-900 border-[var(--border-tertiary)] text-neutral-50 rounded-xl"
                          />
                        </FormControl>
                        <FormMessage className="inline-block" />
                        <FormDescription>
                          Password must be at least 8 characters long and
                          include at least one uppercase letter, one lowercase
                          letter, and one number.
                        </FormDescription>
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name="phone"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel className="text-neutral-50">
                          Phone Number
                        </FormLabel>
                        <div className="flex gap-1.5">
                          <Select
                            value={selectedCountryCode}
                            onValueChange={setSelectedCountryCode}
                          >
                            <SelectTrigger className="min-w-20 w-fit bg-gray-900 border-[var(--border-tertiary)] text-neutral-50 rounded-xl">
                              <SelectValue placeholder="Code" />
                            </SelectTrigger>
                            <SelectContent>
                              {countries
                                .sort((a, b) => a.code - b.code)
                                .map((country) => (
                                  <SelectItem
                                    key={country.name}
                                    value={`+${country.code}`}
                                  >
                                    {country.flag} +{country.code}
                                  </SelectItem>
                                ))}
                            </SelectContent>
                          </Select>
                          <FormControl>
                            <Input
                              {...field}
                              placeholder={t("phone")}
                              className="flex-1 bg-gray-900 border-[var(--border-tertiary)] text-neutral-50 rounded-xl"
                            />
                          </FormControl>
                        </div>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <div className="flex items-center mx-auto gap-2">
                    <Checkbox
                      id="newsletter text-neutral-50"
                      checked={newsletter}
                      onClick={() => setNewsletter(!newsletter)}
                    />
                    <label
                      htmlFor="newsletter"
                      className="text-neutral-50 text-sm font-normal"
                    >
                      <span>Sign me up for the </span>
                      <a
                        href="https://substack.com/@gameaway"
                        target="_blank"
                        rel="noreferrer"
                        className="underline"
                      >
                        GameAway newsletter
                      </a>
                    </label>
                  </div>
                  {signingUp ? (
                    <Button disabled className="w-full rounded-full">
                      <Loader2 className="animate-spin" />
                      Signing Up
                    </Button>
                  ) : (
                    <Button type="submit" className="w-full rounded-full">
                      Sign Up
                    </Button>
                  )}
                </form>
              </Form>
              <div className="self-stretch justify-center items-center gap-2.5 inline-flex">
                <div className="text-center">
                  <span className="text-neutral-50 text-sm font-normal leading-[14px]">
                    {t("already_have_account")}{" "}
                  </span>
                  <a
                    href="/signin"
                    className="text-neutral-50 text-sm font-normal underline leading-[14px]"
                  >
                    {t("sign_in_here")}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <img
          className="lg:w-1/2 lg:block hidden h-screen object-cover"
          src="./auth_side_image.webp"
          alt="background"
        />
      </div>
    </div>
  );
}

export default SignUpPage;

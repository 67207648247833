import React from "react";
import AuthContext from "../../components/AuthContext";
import SignedOutHeader from "../../components/SignedOutHeader/SignedOutHeader";
import ContactUs from "../../components/ContactUs/ContactUs";
import Footer from "../../components/Footer/Footer";
import { Card } from "@/components/ui/card";

class ContactUsPage extends React.Component {
  static contextType = AuthContext;

  render() {
    const { userEmail, signOut } = this.context;

    return (
      <div className="pt-0 max-w-6xl px-6 my-0 mx-auto min-h-screen grid text-neutral-50">
        <SignedOutHeader context={this.context} />
        <h2 className="text-4xl font-extrabold no-underline pb-10">
          Contact Us
        </h2>

        <div className="w-fit">
          <ContactUs />

          <Card className="mt-5 p-4 flex-1 bg-[var(--ring-offset)] rounded-3xl shadow-sm border border-[var(--button-background)]">
            <div className="text-primary">Address</div>
            <p className="address-line">GameAway Cloud Technologies LLP</p>
            <p className="address-line">
              Unit 3, 2nd Floor, Mahalaxmi Industrial Estate
            </p>
            <p className="address-line">Delisle Road, Worli</p>
            <p className="address-line">Mumbai 400013</p>
            <p className="address-line">Maharashtra</p>
            <p className="address-line">India</p>
          </Card>
        </div>
        <Footer />
      </div>
    );
  }
}

export default ContactUsPage;

import React, {
  Component,
  useState,
  useEffect,
  useRef,
  useCallback,
} from "react";
import {
  createBrowserRouter,
  RouterProvider,
  Navigate,
} from "react-router-dom";
import App from "../../App";
import PricingPageBundles from "../PricingPageRedesign/PricingPageBundles";
import { AuthContextProvider } from "../AuthContext.js";
import { Amplify, Auth, Hub } from "aws-amplify";
import AboutUsPage from "../../pages/AboutUsPage/AboutUsPage";
import ContactUsPage from "../../pages/ContactUsPage/ContactUsPage";
import PrivacyPolicyPage from "../../pages/PrivacyPolicyPage/PrivacyPolicyPage";
import TermsAndConditionsPage from "../../pages/TermsAndConditionsPage/TermsAndConditionsPage";
import CancellationRefundPolicyPage from "../../pages/CancellationRefundPolicyPage/CancellationRefundPolicyPage";
import GameListPage from "../../pages/GameListPage/GameListPage";
import ShippingDeliveryPolicyPage from "../../pages/ShippingDeliveryPolicyPage/ShippingDeliveryPolicyPage";
import ConfirmationPage from "../../pages/ConfirmationPage/ConfirmationPage";
import UserPage from "../../pages/UserPage/UserPage";
import StripeSkeleton from "../../pages/StripeSkeleton/StripeSkeleton.js";
import { usePostHog } from "posthog-js/react";
import FaqPage from "../FaqPage/FaqPage.js";
import SignUpPage from "../SignUpPage/SignUpPage.js";
import SignInPage from "../SignInPage/SignInPage.js";
import ForgotPasswordPage from "../ForgotPasswordPage/ForgotPasswordPage.js";
import ConfirmAccountPage from "../ConfirmAccountPage/ConfirmAccountPage.js";
import ResetPasswordPage from "../ResetPasswordPage/ResetPasswordPage.js";
import PasswordUpdatedConfirmationPage from "../PasswordUpdatedConfirmationPage/PasswordUpdatedConfirmationPage.js";
import i18n from "../../i18n.js";
import { Canvas, useFrame } from "@react-three/fiber";
import * as THREE from "three";
import PricingPage2 from "../PricingPage2/PricingPage2";
import CustomPricing from "../CustomPricing/CustomPricing";

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    errorElement: <div>Sorry, that path was not found</div>,
  },
  {
    path: "stripe-skeleton",
    element: <StripeSkeleton />,
  },
  {
    path: "pricing",
    element: <PricingPage2 />,
  },
  {
    path: "custom-pricing",
    element: <CustomPricing />,
  },
  {
    path: "faq",
    element: <FaqPage />,
  },
  {
    path: "user",
    element: <UserPage />,
  },
  {
    path: "about-us",
    element: <AboutUsPage />,
  },
  {
    path: "contact-us",
    element: <ContactUsPage />,
  },
  {
    path: "privacy-policy",
    element: <PrivacyPolicyPage />,
  },
  {
    path: "terms-and-conditions",
    element: <TermsAndConditionsPage />,
  },
  {
    path: "cancellation-refund-policy",
    element: <CancellationRefundPolicyPage />,
  },
  {
    path: "shipping-delivery-policy",
    element: <ShippingDeliveryPolicyPage />,
  },
  {
    path: "gamelist",
    element: <GameListPage />,
  },
  {
    path: "confirmation",
    element: <ConfirmationPage />,
  },
  {
    path: "signup",
    element: <SignUpPage />,
  },
  {
    path: "signin",
    element: <SignInPage />,
  },
  {
    path: "forgot-password",
    element: <ForgotPasswordPage />,
  },
  {
    path: "reset-password",
    element: <ResetPasswordPage />,
  },
  {
    path: "confirm-account",
    element: <ConfirmAccountPage />,
  },
  {
    path: "password-updated-confirmation",
    element: <PasswordUpdatedConfirmationPage />,
  },
]);

const ngrokUrl =
  "https://3034-2405-201-a-907e-7df9-9d4a-ad9d-12a7.ngrok-free.app";

Amplify.configure({
  Auth: {
    region: "ap-south-1",
    userPoolId: "ap-south-1_qmxXtE0Ab",
    userPoolWebClientId: "7a8sg1malki9d6gp8cfgmsm9mn",
    mandatorySignIn: true,
    oauth: {
      domain: "auth.gameaway.in",
      scope: [
        "email",
        "openid",
        "phone",
        "profile",
        "aws.cognito.signin.user.admin",
      ],
      redirectSignIn: process.env.REACT_APP_ISDEV
        ? ngrokUrl
        : "https://www.gameaway.in",
      redirectSignOut: process.env.REACT_APP_ISDEV
        ? ngrokUrl
        : "https://www.gameaway.in",
      responseType: "code",
    },
  },
});

const AuthWrapper = () => {
  const [user, setUser] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [stripeCustomerId, setStripeCustomerId] = useState(null);
  const [rpCustomerId, setRpCustomerId] = useState(null);
  const [sub, setSub] = useState(null);
  const [userPlan, setUserPlan] = useState(null);
  const [country, setCountry] = useState(null);
  const [paymentGateway, setPaymentGateway] = useState(null);

  const posthog = usePostHog();

  useEffect(() => {
    if (userPlan) {
      console.log("userPlan is set:", userPlan);
      // You can place more code here that needs to run when userPlan changes
      // For example, you might want to validate the plan, update UI elements,
      // or trigger notifications, analytics events, etc.
    } else {
      console.log("userPlan is not set or has been reset");
      // Handle the absence or resetting of userPlan if needed
    }
  }, [userPlan]); // This useEffect runs only when userPlan changes

  const fetchUserPlan = useCallback(async (idToken) => {
    try {
      const planData = await fetch(
        process.env.NODE_ENV === "development"
          ? "https://umbncagcn6.execute-api.ap-south-1.amazonaws.com/default/getActiveSubscriptions-dev-hello"
          : "https://uokjomhvk6.execute-api.ap-south-1.amazonaws.com/default/getActiveSubscriptions-prod-hello",
        {
          headers: {
            "Content-Type": "application/json;charset=UTF-8",
            Authorization: idToken?.jwtToken,
          },
        }
      );
      const planDetails = await planData.json();
      setUserPlan(planDetails); // Storing in global state
    } catch (error) {
      console.error("Failed to fetch user plan:", error);
    }
  });

  const determineCountry = async () => {
    try {
      // Fetch the IP address
      const ipResponse = await fetch("https://api.ipify.org?format=json");
      const ipData = await ipResponse.json();
      const ip = ipData.ip;

      // Fetch location data using the IP
      const locationResponse = await fetch(`https://ipinfo.io/${ip}/json`);
      const locationData = await locationResponse.json();

      setCountry(locationData.country);
      if (locationData.country === "IN") {
        setPaymentGateway("razorpay");
      } else {
        setPaymentGateway("stripe");
      }
      console.log("Set country to " + locationData.country);
    } catch (error) {
      try {
        console.error("Error fetching IP or location:", error);
        const clientTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        console.log("Client timezone: " + clientTimezone);
        if (
          clientTimezone === "Asia/Kolkata" ||
          clientTimezone === "Asia/Calcutta"
        ) {
          console.log("setting payment gateway to razorpay");
          setPaymentGateway("razorpay");
        } else {
          console.log("setting payment gateway to stripe");
          setPaymentGateway("stripe");
        }
      } catch (error) {
        console.log(error);
        setPaymentGateway("razorpay");
      }
    }
  };

  useEffect(() => {
    const listener = ({ payload: { event, data } }) => {
      switch (event) {
        // Handle authentication events...
        case "signIn":
        case "cognitoHostedUI":
          setUser(data);
          Auth.currentSession()
            .then((data) => {
              let idToken = data.getIdToken();
              let email = idToken.payload.email;
              setUserEmail(email);
              fetchUserPlan(idToken);
              // Here you might want to identify the user again with PostHog
              const userSub = idToken.payload.sub; // Unique identifier for the user
              setSub(userSub);
              posthog.identify(userSub, { email: email });
            })
            .catch((err) => console.log(err));
          break;
        case "signIn_failure":
        case "cognitoHostedUI_failure":
          console.log("Error", data);
          break;
        case "signOut":
          setUser(null);
          setUserEmail("");
          setStripeCustomerId(null);
          setSub(null);
          break;
        default:
          console.log("Unhandled auth event", event);
          console.log(data);
      }
    };

    Hub.listen("auth", listener);

    const storedLanguage = localStorage.getItem("language") || "en";
    i18n.changeLanguage(storedLanguage);

    Auth.currentAuthenticatedUser()
      .then((currentUser) => {
        setUser(currentUser);
        determineCountry();
        const storedReferrerUsername =
          sessionStorage.getItem("referrerUsername");
        if (
          storedReferrerUsername &&
          !currentUser?.signInUserSession?.idToken.payload["custom:signedUp"]
        ) {
          Auth.updateUserAttributes(currentUser, {
            "custom:referrerUsername": storedReferrerUsername,
          }).catch((err) => console.log("Failed to update attributes:", err));
          posthog?.capture("referral_applied", {
            referrerUsername: storedReferrerUsername,
            referreeUsername: user.username,
            referreeEmail: user.email,
          });
        }
        if (
          !currentUser?.signInUserSession?.idToken.payload["custom:signedUp"]
        ) {
          Auth.updateUserAttributes(currentUser, {
            "custom:signedUp": "true",
          }).catch((err) => console.log("Failed to update signedUp:", err));
        }
        const email = currentUser?.signInUserSession?.idToken?.payload?.email;
        const userSub = currentUser?.signInUserSession?.idToken?.payload?.sub;
        const phoneNumber =
          currentUser?.signInUserSession?.idToken?.payload?.phone_number;
        const customerID =
          currentUser?.signInUserSession?.idToken?.payload?.[
            "custom:stripeCustomerId"
          ];
        const rpCustomerID =
          currentUser?.signInUserSession?.idToken?.payload?.[
            "custom:rpCustomerId"
          ];
        setUserEmail(email);
        setSub(userSub);
        setStripeCustomerId(customerID);
        setRpCustomerId(rpCustomerID);

        // Identify the user with PostHog
        posthog?.identify(userSub, {
          email: email,
          stripeCustomerId: customerID,
          rpCustomerId: rpCustomerID,
          phoneNumber: phoneNumber,
        });
      })
      .catch((error) => console.log(error));

    // Cleanup listener
    return () => {
      Hub.remove("auth", listener);
    };
  }, [posthog]);

  const signOut = async () => {
    Auth.signOut();
  };

  const signIn = async () => {
    Auth.federatedSignIn();
  };

  const fragmentShader = `
  uniform vec2 u_resolution;
  uniform float u_time;
  uniform vec3 u_color;
  uniform vec4 u_background;
  uniform float u_speed;
  uniform float u_detail;

  mat2 m(float a) {
      float c = cos(a), s = sin(a);
      return mat2(c, -s, s, c);
  }

  float rgb2luma(in vec3 color) {
      return dot(color, vec3(0.299, 0.587, 0.114));
  }

  float luma(in vec4 color) {
      return rgb2luma(color.rgb);
  }

  float map(vec3 p) {
      float t = u_time * u_speed;
      p.xz *= m(t * 0.4);
      p.xy *= m(t * 0.1);
      vec3 q = p * 2.0 + t;
      return length(p + vec3(sin(t * u_speed * 0.1))) * log(length(p) + 0.9) + cos(q.x + sin(q.z + cos(q.y))) * 0.5 - 1.0;
  }

  out vec4 outColor;

  void main() {
      vec2 a = gl_FragCoord.xy / u_resolution.xy - vec2(0.5, 0.5);
      vec3 cl = vec3(0.0);
      float d = 2.5;
      for (float i = 0.0; i <= 1.0 + 20.0 * u_detail; i++) {
          vec3 p = vec3(0, 0, 4.0) + normalize(vec3(a, -1.0)) * d;
          float rz = map(p);
          float f = clamp((rz - map(p + 0.1)) * 0.5, -0.1, 1.0);
          vec3 l = vec3(0.1, 0.3, 0.4) + vec3(5.0, 2.5, 3.0) * f;
          cl = cl * l + smoothstep(2.5, 0.0, rz) * 0.6 * l;
          d += min(rz, 1.0);
      }
      vec4 color = vec4(min(u_color, cl), 1.0);
      color.r = max(u_background.r, color.r);
      color.g = max(u_background.g, color.g);
      color.b = max(u_background.b, color.b);
      outColor = color;
  }`;

  const vertexShader = `
  void main() {
      gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
  }`;

  const TextureMesh = () => {
    const mesh = useRef(null);

    useFrame((state) => {
      const { clock, mouse, gl, size } = state;
      if (mesh.current) {
        mesh.current.material.uniforms.u_mouse.value = [
          mouse.x / 2 + 0.5,
          mouse.y / 2 + 0.5,
        ];
        mesh.current.material.uniforms.u_time.value = clock.getElapsedTime();
        mesh.current.material.uniforms.u_resolution.value = [
          size.width,
          size.height,
        ];
      }
    });

    return (
      <mesh ref={mesh} position={[0, 0, 0]} scale={1} rotation={[0, 0, 0]}>
        <planeGeometry args={[1024, 1024]} />
        <shaderMaterial
          fragmentShader={fragmentShader}
          vertexShader={vertexShader}
          uniforms={{
            u_color: { value: new THREE.Color(0.3137254901960784, 0, 1) },
            u_background: { value: new THREE.Vector4(0, 0, 0, 1) },
            u_speed: { value: 0.1 },
            u_detail: { value: 0.044 },
            u_time: { value: 0 },
            u_mouse: { value: [0, 0] },
            u_resolution: { value: [1024, 1024] },
          }}
          wireframe={false}
          dithering={false}
          flatShading={true}
          doubleSided={true}
          glslVersion={THREE.GLSL3}
        />
      </mesh>
    );
  };

  return (
    <div style={{ position: "relative", width: "100%", height: "100vh" }}>
      <Canvas
        style={{
          zIndex: "-1",
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          overflow: "hidden",
        }}
        camera={{ fov: 75, near: 0.1, far: 1000, position: [0, 0, 5] }}
        gl={{
          preserveDrawingBuffer: true,
          premultipliedAlpha: false,
          alpha: true,
          transparent: true,
          antialias: true,
          precision: "highp",
          powerPreference: "high-performance",
        }}
      >
        <TextureMesh />
      </Canvas>
      <AuthContextProvider
        value={{
          user,
          userEmail,
          signOut,
          signIn,
          stripeCustomerId,
          rpCustomerId,
          sub,
          userPlan,
          fetchUserPlan,
          country,
          paymentGateway,
          determineCountry,
        }}
      >
        <RouterProvider router={router} />
      </AuthContextProvider>
    </div>
  );
};

export default AuthWrapper;

import { useToast } from "@/hooks/use-toast";
import { useEffect } from "react";

import React from "react";
import { useTranslation } from "react-i18next";
import { Button } from "@/components/ui/button";
import {
  Card,
  CardContent,
  CardHeader,
  CardFooter,
} from "@/components/ui/card";
import { Mail } from "lucide-react";

export default function ContactUs() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const { t } = useTranslation();
  const { toast } = useToast();

  return (
    <Card className="bg-[var(--ring-offset)] rounded-3xl shadow-sm border border-[var(--button-background)] flex-col justify-start items-start inline-flex w-full">
      <CardHeader className="grow shrink basis-0 flex-col justify-start items-start inline-flex">
        <div className="text-xl font-semibold leading-7">
          We’d love to hear from you
        </div>
        <div className="text-base text-muted-foreground">
          Our friendly team is always here to chat
        </div>
      </CardHeader>
      <CardContent>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
          {/* Email Card */}
          <Card className="rounded-2xl border-secondary bg-[var(--ring-offset)] border border-[var(--button-background)] flex-col justify-start items-start flex">
            <CardHeader>
              <div className="h-10 w-10 bg-gray-900 rounded-lg flex items-center justify-center border border-secondary">
                <Mail className="h-5 w-5" />
              </div>
            </CardHeader>
            <CardContent>
              <h3 className="text-lg font-bold">Email</h3>
              <p className="text-sm text-muted-foreground">
                For support or questions, email us anytime.
              </p>
            </CardContent>
            <CardFooter>
              <Button
                variant="secondary"
                size="sm"
                className="rounded-full"
                onClick={() => {
                  navigator.clipboard.writeText("support@gameaway.in");
                  toast({
                    description: "Copied to clipboard",
                    position: "bottom-center",
                  });
                }}
              >
                Copy Email
              </Button>
            </CardFooter>
          </Card>

          {/* Discord Card */}
          <Card className="rounded-2xl border-secondary bg-[var(--ring-offset)] border border-[var(--button-background)] flex-col justify-start items-start flex">
            <CardHeader>
              <div className="h-10 w-10 bg-gray-900 rounded-lg flex items-center justify-center border border-secondary">
                <svg
                  width="20"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 127.14 96.36"
                >
                  <path
                    fill="#5865f2"
                    d="M107.7,8.07A105.15,105.15,0,0,0,81.47,0a72.06,72.06,0,0,0-3.36,6.83A97.68,97.68,0,0,0,49,6.83,72.37,72.37,0,0,0,45.64,0,105.89,105.89,0,0,0,19.39,8.09C2.79,32.65-1.71,56.6.54,80.21h0A105.73,105.73,0,0,0,32.71,96.36,77.7,77.7,0,0,0,39.6,85.25a68.42,68.42,0,0,1-10.85-5.18c.91-.66,1.8-1.34,2.66-2a75.57,75.57,0,0,0,64.32,0c.87.71,1.76,1.39,2.66,2a68.68,68.68,0,0,1-10.87,5.19,77,77,0,0,0,6.89,11.1A105.25,105.25,0,0,0,126.6,80.22h0C129.24,52.84,122.09,29.11,107.7,8.07ZM42.45,65.69C36.18,65.69,31,60,31,53s5-12.74,11.43-12.74S54,46,53.89,53,48.84,65.69,42.45,65.69Zm42.24,0C78.41,65.69,73.25,60,73.25,53s5-12.74,11.44-12.74S96.23,46,96.12,53,91.08,65.69,84.69,65.69Z"
                  />
                </svg>
              </div>
            </CardHeader>
            <CardContent>
              <h3 className="text-lg font-bold">Discord</h3>
              <p className="text-sm text-muted-foreground">
                Join our Discord for live updates and chats.
              </p>
            </CardContent>
            <CardFooter>
              <a href="https://discord.gg/enfJGFcBrA" target="_blank">
                <Button variant="secondary" size="sm" className="rounded-full">
                  Join Our Discord
                </Button>
              </a>
            </CardFooter>
          </Card>

          {/* Telegram Card */}
          <Card className="rounded-2xl border-secondary bg-[var(--ring-offset)] border border-[var(--button-background)] flex-col justify-start items-start flex">
            <CardHeader>
              <div className="h-10 w-10 bg-gray-900 rounded-lg flex items-center justify-center border border-secondary">
                <svg
                  version="1.1"
                  height="20"
                  viewBox="0 0 1000 1000"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <title>Artboard</title>
                  <desc>Created with Sketch.</desc>
                  <defs>
                    <linearGradient id="a" x1="50%" x2="50%" y2="99.258%">
                      <stop stop-color="#2AABEE" offset="0" />
                      <stop stop-color="#229ED9" offset="1" />
                    </linearGradient>
                  </defs>
                  <g fill-rule="evenodd">
                    <circle cx="500" cy="500" r="500" fill="url(#a)" />
                    <path
                      d="m226.33 494.72c145.76-63.505 242.96-105.37 291.59-125.6 138.86-57.755 167.71-67.787 186.51-68.119 4.1362-0.072862 13.384 0.95221 19.375 5.8132 5.0584 4.1045 6.4501 9.6491 7.1161 13.541 0.666 3.8915 1.4953 12.756 0.83608 19.683-7.5246 79.062-40.084 270.92-56.648 359.47-7.0089 37.469-20.81 50.032-34.17 51.262-29.036 2.6719-51.085-19.189-79.207-37.624-44.007-28.847-68.867-46.804-111.58-74.953-49.366-32.531-17.364-50.411 10.769-79.631 7.3626-7.6471 135.3-124.01 137.77-134.57 0.30968-1.3202 0.59708-6.2414-2.3265-8.8399s-7.2385-1.7099-10.352-1.0032c-4.4137 1.0017-74.715 47.468-210.9 139.4-19.955 13.702-38.029 20.379-54.223 20.029-17.853-0.3857-52.194-10.094-77.723-18.393-31.313-10.178-56.199-15.56-54.032-32.846 1.1287-9.0037 13.528-18.212 37.197-27.624z"
                      fill="#fff"
                    />
                  </g>
                </svg>
              </div>
            </CardHeader>
            <CardContent>
              <h3 className="text-lg font-bold">Telegram</h3>
              <p className="text-sm text-muted-foreground">
                Connect with us through our Telegram group.
              </p>
            </CardContent>
            <CardFooter>
              <a href="https://t.me/+O2N-1qn2_vxhNDY9" target="_blank">
                <Button variant="secondary" size="sm" className="rounded-full">
                  Join Our Telegram
                </Button>
              </a>
            </CardFooter>
          </Card>

          {/* WhatsApp Card */}
          <Card className="rounded-2xl border-secondary bg-[var(--ring-offset)] border border-[var(--button-background)] flex-col justify-start items-start flex">
            <CardHeader>
              <div className="h-10 w-10 bg-gray-900 rounded-lg flex items-center justify-center border border-secondary">
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 360 362"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M307.546 52.5655C273.709 18.685 228.706 0.0171895 180.756 0C81.951 0 1.53846 80.404 1.50408 179.235C1.48689 210.829 9.74646 241.667 25.4319 268.844L0 361.736L95.0236 336.811C121.203 351.096 150.683 358.616 180.679 358.625H180.756C279.544 358.625 359.966 278.212 360 179.381C360.017 131.483 341.392 86.4547 307.546 52.5741V52.5655ZM180.756 328.354H180.696C153.966 328.346 127.744 321.16 104.865 307.589L99.4242 304.358L43.034 319.149L58.0834 264.168L54.5423 258.53C39.6304 234.809 31.749 207.391 31.7662 179.244C31.8006 97.1036 98.6334 30.2707 180.817 30.2707C220.61 30.2879 258.015 45.8015 286.145 73.9665C314.276 102.123 329.755 139.562 329.738 179.364C329.703 261.513 262.871 328.346 180.756 328.346V328.354ZM262.475 216.777C257.997 214.534 235.978 203.704 231.869 202.209C227.761 200.713 224.779 199.966 221.796 204.452C218.814 208.939 210.228 219.029 207.615 222.011C205.002 225.002 202.389 225.372 197.911 223.128C193.434 220.885 179.003 216.158 161.891 200.902C148.578 189.024 139.587 174.362 136.975 169.875C134.362 165.389 136.7 162.965 138.934 160.739C140.945 158.728 143.412 155.505 145.655 152.892C147.899 150.279 148.638 148.406 150.133 145.423C151.629 142.432 150.881 139.82 149.764 137.576C148.646 135.333 139.691 113.287 135.952 104.323C132.316 95.5909 128.621 96.777 125.879 96.6309C123.266 96.5019 120.284 96.4762 117.293 96.4762C114.302 96.4762 109.454 97.5935 105.346 102.08C101.238 106.566 89.6691 117.404 89.6691 139.441C89.6691 161.478 105.716 182.785 107.959 185.776C110.202 188.767 139.544 234.001 184.469 253.408C195.153 258.023 203.498 260.782 210.004 262.845C220.731 266.257 230.494 265.776 238.212 264.624C246.816 263.335 264.71 253.786 268.44 243.326C272.17 232.866 272.17 223.893 271.053 222.028C269.936 220.163 266.945 219.037 262.467 216.794L262.475 216.777Z"
                    fill="#25D366"
                  />
                </svg>
              </div>
            </CardHeader>
            <CardContent>
              <h3 className="text-lg font-bold">WhatsApp</h3>
              <p className="text-sm text-muted-foreground">
                You can also message us on WhatsApp.
              </p>
            </CardContent>
            <CardFooter>
              <a
                href="https://chat.whatsapp.com/DiRKnzQk0OE6woulWCaN3p"
                target="_blank"
              >
                <Button variant="secondary" size="sm" className="rounded-full">
                  Join Our Whatsapp
                </Button>
              </a>
            </CardFooter>
          </Card>
        </div>
      </CardContent>
    </Card>
  );
}

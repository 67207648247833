import SignedOutHeader from "../SignedOutHeader/SignedOutHeader";
import { useState, useEffect } from "react";
import { Auth, Hub } from "aws-amplify";
import { useNavigate, useLocation } from "react-router-dom";
import Footer from "../../components/Footer/Footer";
import { useTranslation } from "react-i18next";
import { usePostHog } from "posthog-js/react";
import { Button } from "../ui/button";
import { Loader2, Mail, ArrowLeft } from "lucide-react";
import { REGEXP_ONLY_DIGITS } from "input-otp";

import {
  InputOTP,
  InputOTPGroup,
  InputOTPSeparator,
  InputOTPSlot,
} from "@/components/ui/input-otp";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { zodResolver } from "@hookform/resolvers/zod";
import * as z from "zod";
import { useForm } from "react-hook-form";

function ConfirmAccountPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [phone, setPhone] = useState("");
  const [code, setCode] = useState("");
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");
  const [submittedCode, setSubmittedCode] = useState(false);
  const [resentCode, setResentCode] = useState(false);
  const { t } = useTranslation();
  const posthog = usePostHog();

  useEffect(() => {
    // Check if username is passed via state; if not, redirect back to the signup page
    if (
      location.state &&
      location.state.username &&
      location.state.password &&
      location.state.phone
    ) {
      setUsername(location.state.username);
      setPassword(location.state.password);
      setPhone(location.state.phone);
    } else {
      navigate("/signup"); // Adjust the route as necessary for your application
    }
  }, [location, navigate]);

  const handleConfirm = async (e) => {
    setSubmittedCode(true);
    e.preventDefault();
    try {
      await Auth.confirmSignUp(username, code);
      await Auth.signIn(username, password); // Add automatic sign-in after confirmation
      navigate("/"); // Redirect to the dashboard or preferred route after sign-in
      setSubmittedCode(false);
      posthog?.capture("sign_up_account_confirmed", {
        email: username,
      });
    } catch (error) {
      console.error("Error confirming account:", error);
      setError(error.message || "Failed to confirm account.");
      setSubmittedCode(false);
      posthog?.capture("sign_up_account_confirmation_error", {
        email: username,
        code: code,
        error: error.message,
      });
    }
  };

  const handleResendCode = async () => {
    setResentCode(true);
    try {
      await Auth.resendSignUp(username);
      setMessage("A new confirmation code has been sent to your email.");
      posthog?.capture("sign_up_account_confirmation_resend_code", {
        email: username,
      });
      setTimeout(() => {
        setResentCode(false);
      }, 15000);
    } catch (error) {
      console.error("Error resending confirmation code:", error);
      setError(error.message || "Failed to resend confirmation code.");
      setResentCode(false);
      posthog?.capture("sign_up_account_confirmation_resend_code_error", {
        email: username,
        error: error.message,
      });
    }
  };

  const formSchema = z.object({
    otp: z.string().length(6, { message: "OTP must be 6 digits" }),
  });

  const form = useForm({
    resolver: zodResolver(formSchema),
    defaultValues: {
      otp: "",
    },
  });

  const onSubmit = async (values) => {
    setSubmittedCode(true);
    try {
      await Auth.confirmSignUp(username, values.otp);
      await Auth.signIn(username, password); // Add automatic sign-in after confirmation
      navigate("/"); // Redirect to the dashboard or preferred route after sign-in
      setSubmittedCode(false);
      posthog?.capture("sign_up_account_confirmed", {
        email: username,
      });
    } catch (error) {
      console.error("Error confirming account:", error);
      setError(error.message || "Failed to confirm account.");
      setSubmittedCode(false);
      posthog?.capture("sign_up_account_confirmation_error", {
        email: username,
        code: values.otp,
        error: error.message,
      });
    }
  };

  return (
    <div className="pt-0 w-full px-0 my-0 mx-auto min-h-screen">
      <div className="flex flex-row">
        <div className="lg:w-1/2 w-full h-screen bg-[var(--ring-offset)] flex-col justify-center items-center gap-2.5 inline-flex">
          <div className="w-14 h-14 p-3.5 bg-[var(--ring-offset)] rounded-xl shadow shadow-inner border border-[var(--button-background)] justify-center items-center inline-flex">
            <div className="w-7 h-7 relative flex-col justify-center items-center flex">
              <Mail className="text-neutral-50 w-full h-full" />
            </div>
          </div>
          <div className="self-stretch rounded-lg flex-col justify-start items-start gap-6 flex w-fit mx-auto">
            <div className="self-stretch flex-col justify-start items-start gap-2 flex">
              <div className="self-stretch text-center text-neutral-50 text-3xl font-bold leading-[30px]">
                {t("check_your_email")}
              </div>
              <div className="self-stretch text-center text-[var(--muted-foreground)] text-base font-normal leading-normal">
                {t("we_sent_verification")}
              </div>
              <div className="self-stretch text-center text-neutral-50 text-base font-normal leading-normal">
                {username}
              </div>
            </div>
            <Form {...form}>
              <form
                onSubmit={form.handleSubmit(onSubmit)}
                className="w-full space-y-4 items-center justify-center"
              >
                <FormField
                  control={form.control}
                  name="otp"
                  render={({ field }) => (
                    <FormItem>
                      <FormControl>
                        <InputOTP
                          maxLength={6}
                          pattern={REGEXP_ONLY_DIGITS}
                          {...field}
                        >
                          <InputOTPGroup className="mx-auto text-neutral-50">
                            <InputOTPSlot index={0} />
                            <InputOTPSlot index={1} />
                            <InputOTPSlot index={2} />
                            <InputOTPSlot index={3} />
                            <InputOTPSlot index={4} />
                            <InputOTPSlot index={5} />
                          </InputOTPGroup>
                        </InputOTP>
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                {submittedCode ? (
                  <Button disabled className="w-full rounded-full">
                    <Loader2 className="animate-spin" />
                    Verifying
                  </Button>
                ) : (
                  <Button type="submit" className="w-full rounded-full">
                    {t("verify_email_address")}
                  </Button>
                )}
              </form>
            </Form>
            <span className="text-red-700 font-normal text-sm mx-auto">
              {error}
            </span>
            <div className="self-stretch pt-4 justify-center items-center gap-2.5 inline-flex">
              {resentCode ? (
                <div className="text-center">
                  <span className="text-neutral-50 text-sm font-normal leading-[14px]">
                    Code resent!
                  </span>
                </div>
              ) : (
                <div className="text-center">
                  <span className="text-neutral-50 text-sm font-normal leading-[14px]">
                    {t("didnt_receive_email")}{" "}
                  </span>
                  <span
                    onClick={handleResendCode}
                    className="text-neutral-50 text-sm font-normal underline leading-[14px] cursor-pointer"
                  >
                    {t("resend_code")}
                  </span>
                </div>
              )}
            </div>
            <a
              href="/signup"
              className="h-9 px-3 py-2 bg-white/0 rounded-full justify-center items-center gap-2 inline-flex mx-auto"
            >
              <div className="w-4 h-4 relative">
                <ArrowLeft className="text-neutral-50 w-full h-full" />
              </div>
              <div className="text-neutral-50 text-sm font-semibold leading-tight">
                {t("back_to_login")}
              </div>
            </a>
          </div>
        </div>
        <img
          className="lg:w-1/2 lg:block hidden h-screen object-cover"
          src="./auth_side_image.webp"
          alt="background"
        />
      </div>
    </div>
  );
}

export default ConfirmAccountPage;

import { useTranslation } from "react-i18next";
import { Button } from "@/components/ui/button";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Slider } from "@/components/ui/slider";

import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  DialogClose,
} from "@/components/ui/dialog";
import { Plus, Loader2 } from "lucide-react";
import { cn } from "@/lib/utils";
import { Skeleton } from "@/components/ui/skeleton";

const DownloadAppDialog = ({ downloadLink, open, setOpen }) => {
  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogContent className="bg-[var(--ring-offset)] rounded-2xl sm:rounded-2xl shadow-[0px_4px_6px_-2px_rgba(0,0,0,0.05)] shadow-lg border border-[var(--button-background)]">
        <DialogHeader>
          <DialogTitle className="text-neutral-50 text-2xl font-semibold">
            Download the GameAway App
          </DialogTitle>
          <DialogDescription className="self-stretch text-[var(--muted-foreground)] text-sm font-medium">
            Download our app for smooth gameplay and effortless setup
          </DialogDescription>
        </DialogHeader>
        <DialogFooter>
          <DialogClose asChild>
            <Button className="rounded-full" type="button" variant="secondary">
              I already have the app
            </Button>
          </DialogClose>
          <Button
            className="rounded-full"
            type="submit"
            onClick={() => {
              window.location.href = downloadLink;
            }}
          >
            Download
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default DownloadAppDialog;

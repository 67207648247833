export const gameList = [
  {
    name: "Asphalt Legends Unite",
    store: "Steam",
    free: true,
  },
  {
    name: "Counter Strike 2",
    store: "Steam",
    free: true,
  },
  {
    name: "Dota 2",
    store: "Steam",
    free: true,
  },
  {
    name: "eFootball",
    store: "Steam",
    free: true,
  },
  {
    name: "Euro Truck Simulator 2",
    store: "Steam",
  },
  {
    name: "Genshin Impact",
    store: "HoYoVerse",
    free: true,
  },
  {
    name: "God of War",
    store: "Steam",
  },
  {
    name: "Grand Theft Auto V Steam",
    store: "Steam",
    hourlyPlanOnly: true,
    image: "Grand Theft Auto V",
  },
  {
    name: "Grand Theft Auto V",
    store: "Epic",
    hourlyPlanOnly: true,
  },
  {
    name: "Red Dead Redemption 2",
    store: "Steam",
  },
  {
    name: "Rocket League",
    store: "Epic ", // keep the space after Epic otherwise it'll try to boot Rocket League - Epic instead
    free: true,
  },
  {
    name: "Spider-Man Miles Morales",
    store: "Steam",
  },
];

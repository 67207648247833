import { useState, useEffect } from "react";
import { Auth } from "aws-amplify";
import Footer from "../Footer/Footer";
import SignedOutHeader from "../SignedOutHeader/SignedOutHeader";
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { usePostHog } from "posthog-js/react";
import { Button } from "../ui/button";
import { Input } from "@/components/ui/input";
import { Loader2, LockKeyhole, ArrowLeft } from "lucide-react";
import { REGEXP_ONLY_DIGITS } from "input-otp";
import {
  InputOTP,
  InputOTPGroup,
  InputOTPSeparator,
  InputOTPSlot,
} from "@/components/ui/input-otp";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import * as z from "zod";
import { useToast } from "@/hooks/use-toast";
import { ToastAction } from "@/components/ui/toast";

function ResetPasswordPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const [username, setUsername] = useState("");
  const [code, setCode] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const [submittedCode, setSubmittedCode] = useState(false);
  const [resentCode, setResentCode] = useState(false);
  const { t } = useTranslation();
  const posthog = usePostHog();
  const { toast } = useToast();

  useEffect(() => {
    // Check if username is passed via state; if so, autofill it
    if (location.state && location.state.username) {
      setUsername(location.state.username);
    }
  }, [location]);

  const handleResendCode = async () => {
    setResentCode(true);
    try {
      await Auth.forgotPassword(username);
      setMessage("A new verification code has been sent to your email.");
      setResentCode(false);
      posthog?.capture("reset_password_email_resent", {
        email: username,
      });
    } catch (error) {
      console.error("Error resending verification code:", error);
      setError(error.message || "Failed to resend verification code.");
      setResentCode(false);
      posthog?.capture("reset_password_email_resent_error", {
        email: username,
        error: error.message,
      });
    }
  };

  const forgotPasswordSchema = z
    .object({
      otp: z.string().length(6, { message: "OTP must be 6 digits" }),
      new_password: z
        .string()
        .min(8, {
          message: "Password must be at least 8 characters long.",
        })
        .regex(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)/, {
          message:
            "Password must include at least one uppercase letter, one lowercase letter and one number.",
        }),
      confirm_new_password: z
        .string()
        .min(8, {
          message: "Password must be at least 8 characters long.",
        })
        .regex(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)/, {
          message:
            "Password must include at least one uppercase letter, one lowercase letter and one number.",
        }),
    })
    .refine((data) => data.new_password === data.confirm_new_password, {
      message: "Passwords don't match",
      path: ["confirm_new_password"],
    });

  const form = useForm({
    resolver: zodResolver(forgotPasswordSchema),
    defaultValues: {
      new_password: "",
      confirm_new_password: "",
      otp: "",
    },
  });

  const onSubmit = async (values) => {
    let newPassword = values.new_password;
    let code = values.otp;
    setSubmittedCode(true);
    try {
      await Auth.forgotPasswordSubmit(username.trim(), code, newPassword);
      await Auth.signIn(username.trim(), newPassword); // Add automatic sign-in after confirmation
      navigate("/password-updated-confirmation");
      setMessage("Password has been successfully reset.");
      setSubmittedCode(false);
      posthog?.capture("reset_password_success", {
        email: username,
      });
    } catch (error) {
      console.error("Error resetting password:", error);
      toast({
        variant: "destructive",
        description: error.message || "Failed to reset password.",
      });
      setError(error.message || "Failed to reset password.");
      setSubmittedCode(false);
      posthog?.capture("reset_password_error", {
        email: username,
        error: error.message,
      });
    }
  };

  return (
    <div className="pt-0 w-full px-0 my-0 mx-auto min-h-screen">
      <div className="flex flex-row">
        <div className="lg:w-1/2 w-full h-screen bg-[var(--ring-offset)] flex-col justify-center items-center gap-2.5 inline-flex">
          <div className="w-14 h-14 p-3.5 bg-[var(--ring-offset)] rounded-xl shadow shadow-inner border border-[var(--button-background)] justify-center items-center inline-flex">
            <div className="w-7 h-7 relative flex-col justify-center items-center flex">
              <LockKeyhole className="text-neutral-50 w-full h-full" />
            </div>
          </div>
          <div className="self-stretch h-[470px] rounded-lg flex-col justify-start items-start gap-6 flex w-fit mx-auto">
            <div className="self-stretch h-[62px] flex-col justify-start items-start gap-2 flex">
              <div className="self-stretch text-center text-neutral-50 text-3xl font-bold leading-[30px]">
                Set new password
              </div>
              <div className="self-stretch text-center text-[var(--muted-foreground)] text-base font-normal leading-normal">
                {t("different_to_previous_passwords")}
              </div>
            </div>
            <Form {...form}>
              <form
                onSubmit={form.handleSubmit(onSubmit)}
                className="w-full space-y-4"
              >
                <FormField
                  control={form.control}
                  name="otp"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel className="text-neutral-50">
                        Confirmation Code
                      </FormLabel>
                      <FormControl>
                        <InputOTP
                          maxLength={6}
                          pattern={REGEXP_ONLY_DIGITS}
                          {...field}
                        >
                          <InputOTPGroup className="mx-auto">
                            <InputOTPSlot index={0} />
                            <InputOTPSlot index={1} />
                            <InputOTPSlot index={2} />
                            <InputOTPSlot index={3} />
                            <InputOTPSlot index={4} />
                            <InputOTPSlot index={5} />
                          </InputOTPGroup>
                        </InputOTP>
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="new_password"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel className="text-neutral-50">
                        New Password
                      </FormLabel>
                      <FormControl>
                        <Input
                          {...field}
                          type="password"
                          placeholder="••••••••"
                          className="bg-gray-900 border-[var(--border-tertiary)] text-neutral-50 rounded-xl py-0"
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="confirm_new_password"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel className="text-neutral-50">
                        Confirm New Password
                      </FormLabel>
                      <FormControl>
                        <Input
                          {...field}
                          type="password"
                          placeholder="••••••••"
                          className="bg-gray-900 border-[var(--border-tertiary)] text-neutral-50 rounded-xl py-0"
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                {submittedCode ? (
                  <Button disabled className="w-full rounded-full">
                    <Loader2 className="animate-spin" />
                    Resetting Password
                  </Button>
                ) : (
                  <Button type="submit" className="w-full rounded-full">
                    Reset Password
                  </Button>
                )}
              </form>
            </Form>
            {resentCode ? (
              <Button
                variant="secondary"
                disabled
                className="w-full rounded-full"
              >
                <Loader2 className="animate-spin" />
                Resending Code
              </Button>
            ) : (
              <Button
                variant="secondary"
                className="w-full rounded-full"
                onClick={handleResendCode}
              >
                {t("resend_code")}
              </Button>
            )}
            <a
              href="/signin"
              className="h-9 px-3 py-2 bg-white/0 rounded-full justify-center items-center gap-2 inline-flex mx-auto"
            >
              <div className="w-4 h-4 relative">
                <ArrowLeft className="text-neutral-50 w-full h-full" />
              </div>
              <div className="text-neutral-50 text-sm font-semibold leading-tight">
                {t("back_to_login")}
              </div>
            </a>
          </div>
        </div>
        <img
          className="lg:w-1/2 lg:block hidden h-screen object-cover"
          src="./login-side-image.webp"
          alt="background"
        />
      </div>
    </div>
  );
}

export default ResetPasswordPage;
